import Cookies from 'js-cookie';
import {
  Button,
  Center,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

import { FirebaseContext } from '../../contexts';

function SignIn({
  onSuccess,
  defaultEmail,
}: {
  onSuccess?: (uid: string, email: string) => void;
  defaultEmail?: string;
}) {
  const { firebase } = useContext(FirebaseContext);
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      email: defaultEmail,
      password: '',
    },
  });

  const {
    register: resetRegister,
    handleSubmit: resetHandleSubmit,
    formState: resetFormState,
  } = useForm({
    defaultValues: {
      email: '',
    },
  });

  const [formType, setFormType] = useState<'forget' | 'login'>('login');
  const [forgetEmailSent, setForgetEmailSent] = useState(false);

  const [errorMsg, setErrorMsg] = useState('');
  const { t } = useTranslation('auth');

  const handleSendPasswordResetEmail = useCallback(
    async (data: any) => {
      try {
        const { email } = data;
        if (!firebase) return;
        await firebase.auth().sendPasswordResetEmail(email);
        setForgetEmailSent(true);
        setErrorMsg('');
      } catch (e) {
        const { message } = e;
        setErrorMsg(message);
      }
    },
    [firebase]
  );

  const handleSignIn = useCallback(
    async (data: any) => {
      try {
        const { email, password } = data;
        if (!firebase) return;

        // Nutshell Live signin verify
        const result = await fetch('/api/signin', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email,
            password,
          }),
        });
        if (result.status === 200) {
          const response = await result.json();
          const { isSU, token } = response;
          if (isSU && token) {
            Cookies.set('_isSU', '1');
            await firebase.auth().signInWithCustomToken(token);

            window.location.href = '/admin/events';
            return;
          }
        }

        const userCredential = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password);
        const { user } = userCredential;
        if (!user) return;
        setErrorMsg('');
        const uid = user.uid;

        if (onSuccess) {
          onSuccess(uid, email);
          return;
        }

        window.location.href = '/admin/events';
      } catch (e) {
        const { message } = e;
        setErrorMsg(message);
      }
    },
    [firebase, onSuccess]
  );

  const { isDirty, isSubmitting } = formState;
  const { isDirty: isResetDirty, isSubmitting: isResetSubmitting } =
    resetFormState;

  const emailInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    emailInputRef.current && emailInputRef.current.focus();
  }, []);

  return formType === 'login' ? (
    <form onSubmit={handleSubmit(handleSignIn)}>
      {errorMsg && (
        <Text as="label" color="red.500">
          {t(errorMsg)}
        </Text>
      )}
      <Stack spacing="6">
        <FormControl id="email" mb={2} isRequired>
          <FormLabel>{t('Email')}</FormLabel>
          <Input
            type="email"
            // ref={emailInputRef}
            isReadOnly={!!defaultEmail}
            {...register('email')}
          />
        </FormControl>
        <FormControl id="password" mb={2} isRequired>
          <Flex justify="space-between">
            <FormLabel>{t('Password')}</FormLabel>
          </Flex>

          <Input {...register('password')} type="password" />
        </FormControl>
        <Button
          isDisabled={!isDirty || isSubmitting}
          isLoading={isSubmitting}
          colorScheme="red"
          mt={4}
          size="lg"
          fontSize="md"
          type="submit"
        >
          {t('Login')}
        </Button>
        <Button
          type="button"
          as="a"
          mt={2}
          size="sm"
          fontSize="sm"
          bg="transparent"
          onClick={() => {
            setFormType((f) => (f === 'login' ? 'forget' : 'login'));
          }}
        >
          {t('Forgot password?')}
        </Button>
      </Stack>
    </form>
  ) : (
    <form onSubmit={resetHandleSubmit(handleSendPasswordResetEmail)}>
      {errorMsg && (
        <Text as="label" color="red.500">
          {t(errorMsg)}
        </Text>
      )}
      {forgetEmailSent ? (
        <Center flexDirection="column">
          <Heading color="blue.500" fontSize="md" p={4}>
            {t('Reset password email sent')}
          </Heading>
          <Button
            type="button"
            as="a"
            mt={2}
            size="sm"
            fontSize="sm"
            bg="transparent"
            onClick={() => {
              setFormType((f) => (f === 'login' ? 'forget' : 'login'));
            }}
          >
            {t('Login')}
          </Button>
        </Center>
      ) : (
        <Stack spacing="6">
          <FormControl id="email" mb={2} isRequired>
            <FormLabel>Email address</FormLabel>
            <Input type="email" {...resetRegister('email')} />
          </FormControl>
          <Button
            disabled={!isResetDirty || isResetSubmitting}
            isLoading={isResetSubmitting}
            colorScheme="red"
            mt={4}
            size="lg"
            fontSize="md"
            type="submit"
          >
            {t('Send reset password email')}
          </Button>
          <Button
            type="button"
            as="a"
            mt={2}
            size="sm"
            fontSize="sm"
            bg="transparent"
            onClick={() => {
              setFormType((f) => (f === 'login' ? 'forget' : 'login'));
            }}
          >
            {t('Login')}
          </Button>
        </Stack>
      )}
    </form>
  );
}

export default SignIn;
