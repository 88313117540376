import {
  Alert,
  AlertIcon,
  Box,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode,
  useColorModeValue,
} from '@chakra-ui/react';
import { NextPage } from 'next';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import * as React from 'react';

import NextChakraLink from '@components/NextChakraLink';
import SignIn from '@components/form/SignIn';
import { useAccountContext } from '@lib/hooks';

import Card from './Card';

const Login: NextPage = () => {
  const { alertMessage } = useAccountContext();
  const { t } = useTranslation(['common', 'auth']);
  const router = useRouter();
  const { redirect } = router.query;

  return (
    <>
      <NextSeo
        title={t('auth:Log in to your account')}
        description={t('auth:Log in now')}
      />
      <Box
        bg={useColorModeValue('gray.50', 'inherit')}
        minH="100vh"
        py="12"
        px={{ base: '4', lg: '8' }}
      >
        <Box maxW="md" mx="auto">
          <Heading textAlign="center" size="xl" fontWeight="extrabold">
            {t('auth:Log in to your account')}
          </Heading>

          {alertMessage && (
            <Stack my={8}>
              <Alert status={alertMessage.type}>
                <AlertIcon />
                {alertMessage.message}
              </Alert>
            </Stack>
          )}

          {/* <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
        <Text as="span">Don&apos;t have an account?</Text>
        <NextChakraLink href="/signup">Start free trial</NextChakraLink>
      </Text> */}

          <Text mt="4" mb="8" align="center" maxW="md" fontWeight="medium">
            <Text as="span">{t("It's great to see you again.")}</Text>
          </Text>
          <Card>
            <SignIn
              onSuccess={
                redirect
                  ? () => (window.location.href = redirect as string)
                  : undefined
              }
            />
            {/* <DividerWithText mt="6">or continue with</DividerWithText>
        <SimpleGrid mt="6" columns={3} spacing="3">
          <Button color="currentColor" variant="outline">
            <VisuallyHidden>Login with Facebook</VisuallyHidden>
            <FaFacebook />
          </Button>
          <Button color="currentColor" variant="outline">
            <VisuallyHidden>Login with Google</VisuallyHidden>
            <FaGoogle />
          </Button>
          <Button color="currentColor" variant="outline">
            <VisuallyHidden>Login with Github</VisuallyHidden>
            <FaGithub />
          </Button>
        </SimpleGrid> */}
          </Card>
        </Box>
        <Text mt="8" align="center" fontWeight="medium">
          <NextChakraLink
            href="/"
            color={mode('blue.600', 'blue.200')}
            display={{ base: 'block', md: 'inline-block' }}
          >
            {t("I'll do it later.")}
          </NextChakraLink>
        </Text>
      </Box>
    </>
  );
};

export default Login;
